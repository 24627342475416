@use 'src/styles/base/colors' as *;
@use 'src/styles/base/icons' as *;

.form-control-toggle {
  &.form-control {
    display: inline-flex;
  }

  &.is-label {
    .label {
      padding-left: 10px;
    }
  }

  &.is-checked {
    .toggle {
      &:before {
        background-color: $color-E30613;
      }

      &:after {
        left: 17px;
        background-color: $color-E30613;
        border-color: $color-E30613;
      }
    }

    &.is-reverse-order {
      .toggle {
        &:after {
          left: auto;
          right: 17px;
          background-color: $color-E30613;
          border-color: $color-E30613;
        }
      }
    }
  }

  &.is-disabled {
    .toggle {
      cursor: not-allowed;

      &::before {
        background-color: $color-D2D1D1;
      }

      &:after {
        background-color: $color-E7E7E7;
        border-color: $color-D2D1D1;
      }
    }

    .label {
      color: $color-A7A6A6;
    }
  }

  &.is-reverse-order {
    &.is-label {
      .label {
        padding-left: 0;
        padding-right: 44px;
        text-align: right;
      }
    }

    .toggle {
      &:before {
        position: absolute;
        right: 0;
        left: auto;
      }

      &:after {
        left: auto;
        right: -3px;
      }
    }

    > .errors-list {
      text-align: right;
      padding-right: 44px;
    }
  }

  .toggle {
    position: relative;
    display: flex;
    cursor: pointer;

    &:before {
      content: '';
      position: relative;
      top: 3px;
      width: 34px;
      height: 14px;
      background-color: $color-7D7B7B;
      border-radius: 10px;
      transition: background-color 0.3s ease-in-out;
    }

    &:after {
      content: '';
      position: absolute;
      left: -3px;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      border: 0.5px solid #7d7b7b;
      box-shadow:
        0px 0px 2px rgba(0, 0, 0, 0.12),
        0px 2px 2px rgba(0, 0, 0, 0.24);
      background-color: $color-FFFFFF;
      transition: all 0.3s ease-in-out;
    }

    .label {
      display: inline-block;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      user-select: none;
    }
  }

  .input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
  }

  > .errors-list {
    padding-left: 44px;
  }
}
