/* Webfont: LatoLatin-Bold */
@font-face {
  font-family: 'Lato';
  src: url('../../assets/fonts/LatoLatin/LatoLatin-Bold.eot'); /* IE9 Compat Modes */
  src:
    url('../../assets/fonts/LatoLatin/LatoLatin-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/LatoLatin/LatoLatin-Bold.woff2') format('woff2'),
    /* Modern Browsers */ url('../../assets/fonts/LatoLatin/LatoLatin-Bold.woff') format('woff'),
    /* Modern Browsers */ url('../../assets/fonts/LatoLatin/LatoLatin-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}

/* Webfont: LatoLatin-BoldItalic */
@font-face {
  font-family: 'Lato';
  src: url('../../assets/fonts/LatoLatin/LatoLatin-BoldItalic.eot'); /* IE9 Compat Modes */
  src:
    url('../../assets/fonts/LatoLatin/LatoLatin-BoldItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/LatoLatin/LatoLatin-BoldItalic.woff2') format('woff2'),
    /* Modern Browsers */ url('../../assets/fonts/LatoLatin/LatoLatin-BoldItalic.woff') format('woff'),
    /* Modern Browsers */ url('../../assets/fonts/LatoLatin/LatoLatin-BoldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 700;
}

/* Webfont: LatoLatin-Semibold */
@font-face {
  font-family: 'LatoLatinWeb';
  src: url('../../assets/fonts/LatoLatin/LatoLatin-Semibold.eot'); /* IE9 Compat Modes */
  src:
    url('../../assets/fonts/LatoLatin/LatoLatin-Semibold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/LatoLatin/LatoLatin-Semibold.woff2') format('woff2'),
    /* Modern Browsers */ url('../../assets/fonts/LatoLatin/LatoLatin-Semibold.woff') format('woff'),
    /* Modern Browsers */ url('../../assets/fonts/LatoLatin/LatoLatin-Semibold.ttf') format('truetype');
  font-style: normal;
  font-weight: 600;
}

/* Webfont: LatoLatin-SemiboldItalic */
@font-face {
  font-family: 'LatoLatinWeb';
  src: url('../../assets/fonts/LatoLatin/LatoLatin-SemiboldItalic.eot'); /* IE9 Compat Modes */
  src:
    url('../../assets/fonts/LatoLatin/LatoLatin-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/LatoLatin/LatoLatin-SemiboldItalic.woff2') format('woff2'),
    /* Modern Browsers */ url('../../assets/fonts/LatoLatin/LatoLatin-SemiboldItalic.woff') format('woff'),
    /* Modern Browsers */ url('../../assets/fonts/LatoLatin/LatoLatin-SemiboldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 600;
}

/* Webfont: LatoLatin-Medium */
@font-face {
  font-family: 'Lato';
  src: url('../../assets/fonts/LatoLatin/LatoLatin-Medium.eot'); /* IE9 Compat Modes */
  src:
    url('../../assets/fonts/LatoLatin/LatoLatin-Medium.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/LatoLatin/LatoLatin-Medium.woff2') format('woff2'),
    /* Modern Browsers */ url('../../assets/fonts/LatoLatin/LatoLatin-Medium.woff') format('woff'),
    /* Modern Browsers */ url('../../assets/fonts/LatoLatin/LatoLatin-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
}

/* Webfont: LatoLatin-MediumItalic */
@font-face {
  font-family: 'Latob';
  src: url('../../assets/fonts/LatoLatin/LatoLatin-MediumItalic.eot'); /* IE9 Compat Modes */
  src:
    url('../../assets/fonts/LatoLatin/LatoLatin-MediumItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/LatoLatin/LatoLatin-MediumItalic.woff2') format('woff2'),
    /* Modern Browsers */ url('../../assets/fonts/LatoLatin/LatoLatin-MediumItalic.woff') format('woff'),
    /* Modern Browsers */ url('../../assets/fonts/LatoLatin/LatoLatin-MediumItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 500;
}
